<template>
  <div class="page-header-index-wide">
    <a-row :gutter="24">
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="总放款额" :total="'$' + (headerData.totalAmout || 0)">
          <a-tooltip style="margin-right: 16px" slot="action">
            <template slot="title">
              <div>总放款额 : {{ ' ¥' + (headerData.totalAmoutCN || 0) }}</div>
              <div>放款本金 : {{ ' ¥' + (headerData.loanTotalAmountCN || 0) }}</div>
            </template>
            <a-icon type="transaction" />
          </a-tooltip>

          <!--<a-tooltip title="累计放款金额"  slot="action">-->
          <!--<a-icon type="info-circle-o" />-->
          <!--</a-tooltip>-->

          <div>
            <trend style="margin-right: 16px">
              <span slot="term">订单</span>
              {{ headerData.totalCount || 0 }}
            </trend>
            <trend style="margin-right: 16px">
              <span slot="term">客户</span>
              {{ headerData.countUser || 0 }}
            </trend>
          </div>
          <template slot="footer"
            >放款本金<span>${{ headerData.loanTotalAmount || 0 }}</span></template
          >
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card :loading="loading" title="在贷余额" :total="'$' + (headerData.totalBalAmt || 0)">
          <a-tooltip style="margin-right: 16px" slot="action">
            <template slot="title">
              <div>在贷余额 : {{ '¥' + (headerData.totalBalAmtCN || 0) }}</div>
              <div>正常 : {{ ' ¥' + (headerData.totalNonOverAmtCN || 0) }}</div>
              <div>逾期 : {{ ' ¥' + (headerData.totalOverAmtCN || 0) }}</div>
            </template>
            <a-icon type="transaction" />
          </a-tooltip>

          <!--<a-tooltip title="在贷余额" slot="action">-->
          <!--<a-icon type="info-circle-o" />-->
          <!--</a-tooltip>-->

          <div>
            <trend style="margin-right: 16px">
              <span slot="term">正常</span>
              ${{ headerData.totalNonOverAmt || 0 }}
            </trend>
            <trend>
              <span slot="term">逾期</span>
              ${{ headerData.totalOverAmt || 0 }}
            </trend>
          </div>
          <template slot="footer">
            <trend style="margin-right: 16px">
              <span slot="term">正常</span>
              {{ headerData.totalNonOverCount || 0 }}
            </trend>
            <trend>
              <span slot="term">逾期</span>
              {{ headerData.totalOverCount || 0 }}
            </trend>
          </template>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card
          :loading="loading"
          title="首逾"
          :total="headerData.firstOverdue ? headerData.firstOverdue + '%' : 0 + '%'"
        >
          <a-tooltip title="指标说明" slot="action">
            <a-icon type="info-circle-o" />
          </a-tooltip>
          <div>
            <mini-area :data="firstOverdueTenList" :height="46" />
          </div>
          <template slot="footer">
            <trend style="margin-right: 16px">
              <span slot="term">新户</span>
              {{ headerData.firstOverdueNew || 0 }}%
            </trend>
            <trend>
              <span slot="term">老户</span>
              {{ headerData.firstOverdueOld || 0 }}%
            </trend>
          </template>
        </chart-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <chart-card
          :loading="loading"
          title="实时逾期率"
          :total="headerData.actualOverdue ? headerData.actualOverdue + '%' : 0 + '%'"
        >
          <a-tooltip title="逾期订单数/到期应还款订单数" slot="action">
            <a-icon type="info-circle-o" />
          </a-tooltip>
          <div>
            <mini-progress
              color="rgb(19, 194, 194)"
              :target="headerData.actualOverdue || 0"
              :percentage="headerData.actualOverdue || 0"
              height="8px"
            />
          </div>
          <template slot="footer">
            <trend style="margin-right: 16px">
              <span slot="term">新户</span>
              {{ headerData.actualOverdueNew || 0 }}%
            </trend>
            <trend>
              <span slot="term">老户</span>
              {{ headerData.actualOverdueOld || 0 }}%
            </trend>
          </template>
        </chart-card>
      </a-col>
    </a-row>

    <a-card :loading="loading" :bordered="false" :body-style="{ padding: '0' }">
      <div class="salesCard">
        <a-tabs default-active-key="1" size="large" :tab-bar-style="{ marginBottom: '24px', paddingLeft: '16px' }">
          <div class="extra-wrapper" slot="tabBarExtraContent">
            <div class="extra-item">
              <a :class="{ 'time-select': true, active: activeIndex == timeType.day }" @click="changeDate('day')"
                >今日</a
              >
              <a :class="{ 'time-select': true, active: activeIndex == timeType.week }" @click="changeDate('week')"
                >本周</a
              >
              <a :class="{ 'time-select': true, active: activeIndex == timeType.month }" @click="changeDate('month')"
                >本月</a
              >
              <a :class="{ 'time-select': true, active: activeIndex == timeType.year }" @click="changeDate('year')"
                >本年</a
              >
            </div>
            <a-range-picker
              :style="{ width: '256px' }"
              v-model="rangeDate"
              format="YYYY-MM-DD"
              @change="changeSelectDate"
            />
          </div>
          <a-tab-pane loading="true" tab="A-S转化漏斗" key="1">
            <a-row>
              <a-col :xl="16" :lg="12" :md="12" :sm="24" :xs="24">
                <div style="padding: 25px; height: 254px; overflow-y: scroll">
                  <h3 style="text-align: center; margin-bottom: 8px">
                    A-S总体转化率 <span style="font-size: 18px">{{ converRateData.totalRate || 0 }}%</span>
                  </h3>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">1</span>注册</span>
                      <span class="fr">{{ converRateData.countRegister || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.countRegisterRate || 0"
                      :percentage="converRateData.countRegisterRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.countRegisterRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">2</span>工作信息完善</span>
                      <span class="fr">{{ converRateData.countCustWorkExt || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.countCustWorkExtRate || 0"
                      :percentage="converRateData.countCustWorkExtRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.countCustWorkExtRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">3</span>基本信息完善</span>
                      <span class="fr">{{ converRateData.countCustExt || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.countCustExtRate || 0"
                      :percentage="converRateData.countCustExtRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.countCustExtRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">4</span>联系人完善</span>
                      <span class="fr">{{ converRateData.countCustLinkman || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.countCustLinkmanRate || 0"
                      :percentage="converRateData.countCustLinkmanRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.countCustLinkmanRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">4</span>实名认证</span>
                      <span class="fr">{{ converRateData.countCertificationNew || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.countCertificationNewRate || 0"
                      :percentage="converRateData.countCustLinkmanRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.countCertificationNewRate || 0 }}%</div>
                  </div>

                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">5</span>银行卡鉴权</span>
                      <span class="fr">{{ converRateData.countCustBank || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.countCustBankRate || 0"
                      :percentage="converRateData.countCustBankRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.countCustBankRate || 0 }}%</div>
                  </div>
                  <!-- div style="margin-bottom: 10px;">
                        <div style="overflow: hidden;">
                            <span class="fl"><span class="serial">2</span>实名认证</span>
                            <span class="fr">{{ converRateData.countCertification || 0 }}人</span>
                        </div>
                        <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCertificationRate || 0" :percentage="converRateData.countCertificationRate || 0" height="20px" />
                        <div style="text-align: center;">{{ converRateData.countCertificationRate || 0 }}%</div>
                    </div>
                    <div style="margin-bottom: 10px;">
                        <div style="overflow: hidden;">
                            <span class="fl"><span class="serial">3</span>人脸活体</span>
                            <span class="fr">{{ converRateData.countFaceVerified || 0 }}人</span>
                        </div>
                        <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countFaceVerifiedRate || 0" :percentage="converRateData.countFaceVerifiedRate || 0" height="20px" />
                        <div style="text-align: center;">{{ converRateData.countFaceVerifiedRate || 0 }}%</div>
                    </div -->
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">6</span>提交订单</span>
                      <span class="fr">{{ converRateData.submitUser || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.submitUserRate || 0"
                      :percentage="converRateData.submitUserRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.submitUserRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">7</span>机审通过</span>
                      <span class="fr">{{ converRateData.machinePass || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.machinePassRate || 0"
                      :percentage="converRateData.machinePassRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.machinePassRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">8</span>人工审批通过</span>
                      <span class="fr">{{ converRateData.accessUser || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.accessUserRate || 0"
                      :percentage="converRateData.accessUserRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.accessUserRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px">
                    <div style="overflow: hidden">
                      <span class="fl"><span class="serial">9</span>放款</span>
                      <span class="fr">{{ converRateData.loanUser || 0 }}人</span>
                    </div>
                    <mini-progress
                      color="rgb(19, 194, 194)"
                      :target="converRateData.loanUserRate || 0"
                      :percentage="converRateData.loanUserRate || 0"
                      height="20px"
                    />
                    <div style="text-align: center">{{ converRateData.loanUserRate || 0 }}%</div>
                  </div>
                </div>
                <!-- <bar :data="barData" :height="254" title="A-S转化率：12.8%" /> -->
              </a-col>
              <a-col :xl="8" :lg="12" :md="12" :sm="24" :xs="24">
                <h4 :style="{ marginBottom: '10px', marginLeft: '10px' }">今日回款情况</h4>
                <div style="margin-left: 10px">
                  <div style="display: flex; margin-right: 10px">
                    <div style="flex: 1; text-align: center">
                      <a-badge status="processing" />
                      还款率
                      <a-badge status="processing" style="opacity: 0" />
                    </div>
                    <div style="flex: 1; text-align: center">
                      <a-badge status="success" />
                      首逾率
                      <a-badge status="success" style="opacity: 0" />
                    </div>
                  </div>
                  <div style="display: flex; margin-right: 10px">
                    <div style="flex: 1; text-align: center">
                      {{ (repayCount || {}).curAlRepayCount || 0 }} / {{ (repayCount || {}).curRepayCount || 0 }}
                    </div>
                    <div style="flex: 1; text-align: center">
                      {{ (repayCount || {}).curRepayCount - (repayCount || {}).curAlRepayCount }} /
                      {{ (repayCount || {}).curRepayCount || 0 }}
                    </div>
                  </div>
                </div>
                <liquid :height="211" :data="repayCountData" />
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-card>
    <div class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="isDesktop() ? 'desktop' : ''">
      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :loading="loading" :bordered="false" title="放款折线图" :style="{ height: '100%' }">
            <a-dropdown :trigger="['click']" placement="bottomLeft" slot="extra">
              <a class="ant-dropdown-link" href="#">
                <a-icon type="ellipsis" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="refreshLendingCount">刷新</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-row :gutter="68">
              <a-col :xs="24" :sm="24" :style="{ marginBottom: ' 24px' }">
                <div style="height: 300px">
                  <c-line :data="lineData" :height="300" />
                </div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card
            class="antd-pro-pages-dashboard-analysis-salesCard"
            :loading="loading"
            :bordered="false"
            title="结清类型占比"
            :style="{ height: '100%' }"
          >
            <div slot="extra" style="height: inherit">
              <!-- style="bottom: 12px;display: inline-block;" -->
              <span class="dashboard-analysis-iconGroup">
                <a-dropdown :trigger="['click']" placement="bottomLeft">
                  <a-icon type="ellipsis" class="ant-dropdown-link" />
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a @click="refreshLoanStatusCount">刷新</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </div>
            <div>
              <!-- style="width: calc(100% - 240px);" -->
              <div>
                <c-pie :data="settleTypeData" :height="300" />
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  ChartCard,
  MiniArea,
  MiniBar,
  MiniProgress,
  RankList,
  Bar,
  Trend,
  NumberInfo,
  MiniSmoothArea,
  Liquid,
  Line,
  Pie,
} from '@/components'
import { mixinDevice } from '@/utils/mixin'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Analysis',
  mixins: [mixinDevice],
  components: {
    ChartCard,
    MiniArea,
    MiniBar,
    MiniProgress,
    RankList,
    Bar,
    cLine: Line,
    cPie: Pie,
    Trend,
    NumberInfo,
    MiniSmoothArea,
    Liquid,
  },
  data() {
    return {
      loading: true,
      timeType: {
        day: 1,
        week: 2,
        month: 3,
        year: 4,
      },
      rangeDate: undefined,
      beginDate: undefined,
      endDate: undefined,
      activeIndex: undefined,
      settleType: {
        UN_SETTLE: '未结清订单',
        EARLY_SETTLED: '提前结清订单',
        SETTLED: '正常结清订单',
        OVERDUE_SETTLED: '逾期结清订单',
        EXTENSION_SETTLED: '展期结清订单',
        OVERDUE_EXTENSION_SETTLED: '逾期展期结清订单',
      },
    }
  },
  computed: {
    ...mapGetters('home', [
      'lendingCountByDate',
      'overView',
      'conversionRate',
      'loanStatusCount',
      'repayCount',
      'loanStatusCount',
    ]),
    headerData() {
      return this.overView || {}
    },
    converRateData() {
      return this.conversionRate || {}
    },
    settleTypeData() {
      return Object.entries(this.loanStatusCount || {}).map((item) => {
        return {
          item: this.settleType[item[0]],
          count: item[1],
        }
      })
    },
    repayCountData() {
      const data = this.repayCount || {}
      // console.log(data.curAlRepayCount);
      // console.log(data.curRepayCount);
      // console.log(isNaN(data.curAlRepayCount / data.curRepayCount) ? 0 : +(data.curAlRepayCount / data.curRepayCount*100).toFixed(1));
      // console.log(isNaN(data.curAlRepayCount / data.curRepayCount) ? 0 : (100 - (data.curAlRepayCount / data.curRepayCount*100)).toFixed(1));
      return [
        {
          transfer: '还款率',
          // path: 'M381.759 0h292l-.64 295.328-100.127-100.096-94.368 94.368C499.808 326.848 512 369.824 512 415.712c0 141.376-114.56 256-256 256-141.376 0-256-114.624-256-256s114.624-256 256-256c48.8 0 94.272 13.92 133.12 37.632l93.376-94.592L381.76 0zM128.032 415.744c0 70.688 57.312 128 128 128s128-57.312 128-128-57.312-128-128-128-128 57.312-128 128z',
          value: isNaN(data.curAlRepayCount / data.curRepayCount)
            ? 0
            : +((data.curAlRepayCount / data.curRepayCount) * 100).toFixed(1),
        },
        {
          transfer: '逾期率',
          value: isNaN(data.curAlRepayCount / data.curRepayCount)
            ? 0
            : (100 - (data.curAlRepayCount / data.curRepayCount) * 100).toFixed(1),
        },
      ]
    },
    firstOverdueTenList() {
      return (this.headerData.firstOverdueTenList || []).map((t) => ({ x: t.dataDt, y: t.dataValue }))
    },
    lineData() {
      return (this.lendingCountByDate || []).map((t) => ({ x: t.dataDt, y: t.dataValue, name: '日期' }))
    },
  },
  created() {
    this.loading = false
    this.requestAllAction().then((res) => {
      // this.loading = false;
    })
    // setTimeout(() => {
    //   this.loading = !this.loading
    // }, 1000)
  },
  methods: {
    ...mapActions('home', [
      'getConversionRate',
      'getLendingCountByDate',
      'getRepayCount',
      'getLoanStatusCount',
      'getOverView',
    ]),
    transformDate(date) {
      return date.format('YYYY-MM-DD')
    },
    changeDate(type) {
      const { transformDate } = this
      const currentDay = transformDate(moment())
      this.activeIndex = this.timeType[type]
      this.beginDate = transformDate(moment().startOf(type))
      this.endDate = transformDate(moment().endOf(type))
      if (moment(currentDay).isBefore(this.endDate)) {
        this.endDate = currentDay
      }
      const { beginDate, endDate } = this
      this.getConversionRate({ beginDate, endDate })
    },
    changeSelectDate([beginDate, endDate]) {
      if (!beginDate || !endDate) return
      this.activeIndex = undefined
      this.beginDate = this.transformDate(beginDate)
      this.endDate = this.transformDate(endDate)
      this.getConversionRate({ beginDate: this.beginDate, endDate: this.endDate })
    },
    refreshLendingCount() {
      this.getLendingCountByDate()
    },
    refreshLoanStatusCount() {
      this.getLoanStatusCount()
    },
    requestAllAction() {
      return Promise.all([
        this.getOverView(),
        this.getConversionRate(),
        this.getLendingCountByDate(),
        this.getRepayCount(),
        this.getLoanStatusCount(),
      ])
    },
  },
}
</script>

<style lang="less" scoped>
.green {
  color: #52c41a;
}

.red {
  color: #f5222d;
}

.time-select {
  color: #00000073;

  &.active {
    color: #1890ff;
  }
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.serial {
  display: inline-block;
  height: 24px;
  width: 24px;
  background: #e5e5e5;
  text-align: center;
  line-height: 24px;
  border-radius: 3px;
}

.amount {
  float: left;
  font-size: 16px;

  .unit {
    font-size: 24px;
  }
}

.loan-amount {
  font-size: 14px;

  p {
    margin: 0;
    padding: 0;
  }

  .main-title {
    color: #1890ff;
  }

  .sub-title {
    color: #00000073;
  }

  .amount {
    float: left;
    font-size: 32px;

    .unit {
      font-size: 16px;
    }
  }

  .grow {
    float: left;
    margin-left: 25px;
    color: #00000073;
  }

  .gross {
    float: right;
    margin-top: -15px;
    border-left: 1px solid #e5e5e5;
    padding-left: 10px;

    .gross-item {
      margin-bottom: 5px;
      color: #00000073;

      .total {
        font-size: 18px;
        color: #000;
      }
    }
  }
}

.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}
</style>
